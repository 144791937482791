<template>
  <a-modal width="45%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form"  :rules="rules">
      <a-form-item class="margin-bottom-10">
        <a-table :data-source="form.skuList" :columns="skuColumns" rowKey="id" :pagination="false" bordered>
          <div slot="addStock" slot-scope="text, record">
            <a-input-number v-model="record.addStock"  :placeholder="$t('通用.输入.请输入')+$t('商品.商品库存')"  />
          </div>
        </a-table>
      </a-form-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-modal>
</template>

<script>
import {mapGetters} from 'vuex'
import {getGoodsStock,updateGoodsStock} from '@/api/goods/goods'
import CustomDictTag from "@/components/DictCustomTag";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      errorMessage:undefined,
      skuColumns: [
        {
          title: this.$t('商品.sku.名称'),
          dataIndex: 'skuName',
          key: 'skuName',
        }, {
          title: this.$t('商品.sku.编码'),
          dataIndex: 'skuCode',
          key: 'skuCode',
        }, {
          title: this.$t('商品.sku.价格'),
          dataIndex: 'price',
          key: 'price',
        },
        {
          title: this.$t('商品.sku.原始库存'),
          dataIndex: 'sourceStock',
          key: 'sourceStock',
        },
        {
          title: this.$t('商品.sku.库存'),
          dataIndex: 'stock',
          key: 'stock',
        },
        {
          title: this.$t('商品.sku.增加/减少库存'),
          dataIndex: 'addStock',
          key: 'addStock',
          scopedSlots: {customRender: 'addStock'}
        }
      ],

      // 表单参数
      form: {
        id: null,
        skuList: [],
        name: null,

        icon: null,

        color: null,

        status: 0,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        skuList: [],
        name: null,
        color: '#FFFFFF',
        icon: null,
        status: 0,
        createTime: null,
        remark: null,
      }

    },

    /** 修改按钮操作 */
    show(goodsId) {
      this.reset()
      this.formType = 2
      getGoodsStock({"id":goodsId}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改库存'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = this.$t('通用.文本.修改成功')
          updateGoodsStock(this.form).then(response => {
            this.$message.success(
              message,
              3
            )
            this.open = false
            this.$emit('ok')
          }).finally(() => {
            this.submitLoading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
